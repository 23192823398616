function Community() {
	return (
		<div className="community jcc-aic w-full">
			<header className="community__header jcc-aic w-full">
				<img src="/media/comm/comm1.png" alt="" />
			</header>

			<div className="community__content w-full">
				<div className="community__content-main">
					<img src="/media/comm/comm2.png" alt="" />
				</div>

				<div className="community__content-rest">
					<img src="/media/comm/comm3.png" alt="" />
				</div>
			</div>
		</div>
	);
}

export default Community;
