import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, Navigate } from "react-router-dom";

import { homeSections, settingsSections } from "../helpers/listOfSections";
import { useLocationChange } from "../helpers/hooks";

import Focus from "../pages/focus";
import Gym from "../pages/gym";
import Community from "../pages/community";
import ProfileRouter from "../pages/profile";

import Footer from "../components/footer";
import Modal from "../components/Modal/modal";
import NotFound from "../pages/404";
import ModulesRouter from "../pages/modules";

function Router(props) {
	useLocationChange((location) => {
		let currentSection = null;
		Object.values({ ...homeSections, ...settingsSections }).forEach((value) => {
			if (value.link === location.pathname) {
				currentSection = value.id;
			}
		});

		props.setCurrentSection(currentSection);
	});

	return (
		<Routes>
			<Route
				path={homeSections.focus.link}
				element={<Focus setModalState={props.setModalState} />}
			/>

			<Route
				path={homeSections.gym.link}
				element={<Gym setModalState={props.setModalState} />}
			/>

			<Route path={homeSections.community.link} element={<Community />} />

			<Route
				path={`${homeSections.modules.link}/*`}
				element={<ModulesRouter setModalState={props.setModalState} />}
			/>

			<Route
				path={`${homeSections.profile.link}/*`}
				element={<ProfileRouter setModalState={props.setModalState} />}
			/>

			<Route path="/" element={<Navigate to={homeSections.focus.link} />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

function App() {
	const [currentSection, setCurrentSection] = useState("focus");
	const [modalState, setModalState] = useState(null);

	return (
		<div className="app">
			<Router
				currentSection={currentSection}
				setCurrentSection={setCurrentSection}
				setModalState={setModalState}
			/>

			{homeSections[currentSection] && (
				<Footer currentSection={currentSection} setCurrentSection={setCurrentSection} />
			)}

			<AnimatePresence exitBeforeEnter>
				{modalState && <Modal modalState={modalState} setModalState={setModalState} />}
			</AnimatePresence>
		</div>
	);
}

export default App;
