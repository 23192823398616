import { Link } from "react-router-dom";

import { settingsSections } from "../helpers/listOfSections";

function Notifications() {
	return (
		<div className="notifications jcfs-aic w-full">
			<header className="notifications__header jcc-aic w-full fixed">
				<img src="/media/notifications/notif1.png" alt="" />

				<Link to={settingsSections.settings.link}>
					<img src="/media/back.svg" alt="" className="notifications__header-button" />
				</Link>
			</header>

			<div className="notifications__content w-full">
				<div className="notifications__content-main jcc-aic wrap">
					<div className="notifications__content-item">
						<img src="/media/notifications/notif2.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notifications;
