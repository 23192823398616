import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { settingsSections } from "../helpers/listOfSections";
import listOfModals from "../helpers/listOfModals";

function Schedule({ setModalState }) {
	return (
		<div className="schedule jcfs-aic w-full">
			<header className="schedule__header jcc-aic w-full fixed">
				<img src="/media/schedule/schedule1.png" alt="" />

				<Link to={settingsSections.settings.link}>
					<img src="/media/back.svg" alt="" className="schedule__header-button" />
				</Link>
			</header>

			<div className="schedule__content w-full">
				<div
					className="schedule__content-main jcc-aic wrap"
					onClick={() => setModalState(listOfModals.schedule)}
				>
					<div className="schedule__content-item">
						<img src="/media/schedule/schedule2.png" alt="" />
					</div>
				</div>

				<div className="schedule__content-main jcc-aic wrap">
					<div className="schedule__content-item">
						<img src="/media/schedule/schedule3.png" alt="" />
					</div>
				</div>

				<div
					className="schedule__content-main jcc-aic wrap"
					onClick={() => setModalState(listOfModals.schedule)}
				>
					<div className="schedule__content-item">
						<img src="/media/schedule/schedule4.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

function ScheduleTimeModal({ setModalState }) {
	const scheduleTime = {
		visible: { bottom: 0 },
		hidden: { bottom: "-110vw" },
		transition: { bounce: 0 },
	};

	return (
		<motion.div
			className="modal__modal modal__scheduleTime jcc-aic wrap"
			variants={scheduleTime}
			transition="transition"
			initial="hidden"
			animate="visible"
			exit="hidden"
		>
			<div
				className="modal__scheduleTime-button w-full jcc-aic"
				onClick={() => setModalState(null)}
			>
				<img src="/media/schedule/scheduleTime1.png" alt="" />
			</div>

			<div className="modal__scheduleTime-item w-full jcc-aic">
				<img src="/media/schedule/scheduleTime2.png" alt="" />
			</div>
		</motion.div>
	);
}

export default Schedule;

export { ScheduleTimeModal };
