import listOfModals from "../helpers/listOfModals";

function Gym({ setModalState }) {
	return (
		<div className="gym jcc-aic w-full">
			<header className="gym__header w-full relative">
				<img src="/media/gym/gym1.svg" alt="" />

				<img
					src="/media/gymbutton.svg"
					alt=""
					className="gym__header-button"
					onClick={() => setModalState(listOfModals.demo)}
				/>
			</header>

			<div className="gym__content w-full">
				<div className="gym__content-main jcc-aic relative">
					<img
						src="/media/gym/gym2.png"
						alt=""
						onClick={() => setModalState(listOfModals.gymTactile)}
					/>

					<img
						src="/media/infoicon.svg"
						alt=""
						className="gym__content-info"
						onClick={() => setModalState(listOfModals.tactile)}
					/>
				</div>

				<div className="gym__content-rest">
					<img
						src="/media/gym/gym3.png"
						alt=""
						onClick={() => setModalState(listOfModals.demo)}
					/>
				</div>
			</div>
		</div>
	);
}

export default Gym;
