import { Link } from "react-router-dom";

import listOfModals from "../helpers/listOfModals";
import { modulesSections } from "../helpers/listOfSections";

function Welcome({ setModalState }) {
	return (
		<div className="welcome jcfs-aic w-full">
			<header className="welcome__header jcc-aic w-full fixed">
				<img src="/media/welcome/welcome1.png" alt="" />

				<Link to={modulesSections.modules.link}>
					<img src="/media/back.svg" alt="" className="welcome__header-button" />
				</Link>
			</header>

			<div className="welcome__content w-full">
				<div className="welcome__content-main jcc-aic wrap">
					<div className="welcome__content-item">
						<img src="/media/welcome/welcome2.png" alt="" />
					</div>

					<div
						className="welcome__content-item"
						onClick={() => setModalState(listOfModals.modulesVideo)}
					>
						<img src="/media/welcome/welcome3.png" alt="" />
					</div>

					<div className="welcome__content-item">
						<img src="/media/welcome/welcome4.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Welcome;
