import { Link } from "react-router-dom";

import { homeSections } from "../helpers/listOfSections";

function NotFound() {
	return (
		<div className="page404">
			<h1 style={{ textAlign: "center", margin: "8vh 0 8vh" }}>
				<strong>Error!</strong>
			</h1>

			<div className="content" style={{ textAlign: "center", marginBottom: "8vh" }}>
				<h3>The page you are looking for was not found.</h3>
			</div>

			<h2 style={{ textAlign: "center" }}>
				<Link to={homeSections.focus.link} className="page404__link">
					Back to the main page
				</Link>
			</h2>
		</div>
	);
}

export default NotFound;
