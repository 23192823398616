import { motion } from "framer-motion";

import listOfModals from "../../helpers/listOfModals";

import { ProfileMenuModal } from "../../pages/profile";
import { ScheduleTimeModal } from "../../pages/schedule";
import { AudioPlayerModal } from "./audioPlayer";
import { ModulesVideoModal } from "../../pages/modules";

const backdrop = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 },
};

function Modal({ modalState, setModalState }) {
	return (
		<div className="modal wh-full jcc-aic fixed">
			<Backdrop modalState={modalState} setModalState={setModalState} />

			{modalState === listOfModals.profileMenu && (
				<ProfileMenuModal setModalState={setModalState} />
			)}

			{modalState === listOfModals.schedule && (
				<ScheduleTimeModal setModalState={setModalState} />
			)}

			{modalState === listOfModals.dailyFocus && (
				<AudioPlayerModal modalState={listOfModals.dailyFocus} />
			)}

			{modalState === listOfModals.gymTactile && (
				<AudioPlayerModal modalState={listOfModals.gymTactile} />
			)}

			{modalState === listOfModals.audiobook && (
				<AudioPlayerModal modalState={listOfModals.audiobook} />
			)}
		</div>
	);
}

function Backdrop({ modalState, setModalState }) {
	const backdropRequired = [
		listOfModals.demo,
		listOfModals.profileMenu,
		listOfModals.tactile,
		listOfModals.dailyFocus,
		listOfModals.gymTactile,
		listOfModals.modulesVideo,
		listOfModals.audiobook,
	];

	function backdropShouldBeEnabled() {
		return backdropRequired.includes(modalState);
	}

	return (
		<motion.div
			className="modal__backdrop wh-full jcc-aic"
			variants={backdrop}
			initial="hidden"
			animate={backdropShouldBeEnabled() && "visible"}
			exit="hidden"
			onClick={() => setModalState(null)}
		>
			{modalState === listOfModals.demo && <DemoModal />}
			{modalState === listOfModals.tactile && <TactileModal />}
			{modalState === listOfModals.modulesVideo && <ModulesVideoModal />}
		</motion.div>
	);
}

function DemoModal() {
	return (
		<div className="modal__modal modal__demo jcc-aic">
			<img src="./media/modal/demo.png" alt="" />
		</div>
	);
}

function TactileModal() {
	return (
		<div className="modal__modal modal__tactile jcc-aic">
			<img src="./media/modal/tactile.png" alt="" />
		</div>
	);
}

export default Modal;
