import listOfModals from "../helpers/listOfModals";

function Focus({ setModalState }) {
	return (
		<div className="focus jcc-aic w-full">
			<header className="focus__header w-full">
				<img src="/media/focus/focus1.svg" alt="" />
			</header>

			<div className="focus__content w-full">
				<div className="focus__content-main jcc-aic">
					<img
						src="/media/focus/focus2.png"
						alt=""
						onClick={() => setModalState(listOfModals.dailyFocus)}
					/>
				</div>
				<div className="focus__content-rest">
					<img
						src="/media/focus/focus3.png"
						alt=""
						onClick={() => setModalState(listOfModals.demo)}
					/>
				</div>
			</div>
		</div>
	);
}

export default Focus;
