import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import listOfModals from "../../helpers/listOfModals";
import links from "../../helpers/links";

import { useAudioPlayer } from "../Audio/hooks";
import Audio from "../Audio/audio";

const requiredRepsCount = 6;

function AudioPlayerModal({ modalState }) {
	const audioPlayer = {
		visible: { bottom: 0 },
		hidden: { bottom: "-100vh" },
		transition: { bounce: 0 },
	};

	const [isFinished, setIsFinished] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [repsCount, setRepsCount] = useState(0);
	const [showReps, setShowReps] = useState(true);
	const [audioSource, setAudioSource] = useState();
	const [className, setClassName] = useState("links.defaultAudio");

	useEffect(() => {
		if (modalState === listOfModals.dailyFocus) {
			setAudioSource(links.dailyFocusAudio);
			setClassName("modal__dailyFocus");
		} else if (modalState === listOfModals.gymTactile) {
			setAudioSource(links.gymTactileAudio);
			setClassName("modal__gymTactile");
		} else if (modalState === listOfModals.audiobook) {
			setAudioSource(links.audiobook);
			setClassName("modal__audiobook");

			setShowReps(false);
		}
	}, [modalState]);

	return (
		<motion.div
			className={`modal__modal modal__audioPlayer jcc-aic ${className}`}
			variants={audioPlayer}
			transition="transition"
			initial="hidden"
			animate="visible"
			exit="hidden"
		>
			{!isFinished ? (
				<AudioPlayerModalMain
					setIsFinished={setIsFinished}
					repsCount={repsCount}
					setRepsCount={setRepsCount}
					isButtonDisabled={isButtonDisabled}
					setIsButtonDisabled={setIsButtonDisabled}
					audioSource={audioSource}
					modalState={modalState}
				/>
			) : (
				<AudioPlayerModalWinner modalState={modalState} />
			)}

			{showReps && (
				<RepsHolder radius={43} stroke={7} repsCount={repsCount} isFinished={isFinished} />
			)}
		</motion.div>
	);
}

function AudioPlayerModalMain({
	setIsFinished,
	repsCount,
	setRepsCount,
	isButtonDisabled,
	setIsButtonDisabled,
	audioSource,
	modalState,
}) {
	const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();

	useEffect(() => {
		let oneRepFraction = duration / requiredRepsCount;
		let currentRepsCount = parseInt(curTime / oneRepFraction);

		setRepsCount((previousRepsCount) => {
			if (currentRepsCount > previousRepsCount || !previousRepsCount) {
				if (!isNaN(currentRepsCount)) {
					return currentRepsCount;
				}
			} else if (currentRepsCount === requiredRepsCount) {
				setIsButtonDisabled(false);
			}

			return previousRepsCount;
		});
	}, [curTime, duration, repsCount, setIsButtonDisabled, setRepsCount]);

	return (
		<div className="modal__audioPlayer-container wh-full jcsb-aic column">
			<div className="modal__audioPlayer-image w-full jcc-aic">
				{modalState === listOfModals.dailyFocus && (
					<img src="/media/focus/dailyFocus1.png" alt="" />
				)}

				{modalState === listOfModals.gymTactile && (
					<img src="/media/gym/gymTactile1.png" alt="" />
				)}

				{modalState === listOfModals.audiobook && (
					<img src="/media/audiobook/audioPlay1.png" alt="" />
				)}
			</div>

			<div className="modal__audioPlayer-holder">
				<div className="modal__audioPlayer-item jcc-aic">
					{modalState === listOfModals.dailyFocus && (
						<img src="/media/focus/dailyFocus2.png" alt="" />
					)}

					{modalState === listOfModals.gymTactile && (
						<img src="/media/gym/gymTactile2.png" alt="" />
					)}

					{modalState === listOfModals.audiobook && (
						<img src="/media/audiobook/audioPlay2.png" alt="" />
					)}
				</div>

				<div className="modal__audioPlayer-player jcc-aic">
					<Audio
						curTime={curTime}
						duration={duration}
						playing={playing}
						setPlaying={setPlaying}
						setClickedTime={setClickedTime}
						audioSource={audioSource}
					/>
				</div>

				<div
					className={`modal__audioPlayer-button jcc-aic ${
						isButtonDisabled ? "modal__audioPlayer-button--disabled" : ""
					}`}
					onClick={() => {
						if (!isButtonDisabled) {
							setIsFinished(true);
						}
					}}
				>
					{modalState === listOfModals.dailyFocus && (
						<img src="/media/focus/dailyFocus3.png" alt="" />
					)}

					{modalState === listOfModals.gymTactile && (
						<img src="/media/gym/gymTactile3.png" alt="" />
					)}

					{modalState === listOfModals.audiobook && (
						<img src="/media/audiobook/audioPlay3.png" alt="" />
					)}
				</div>
			</div>
		</div>
	);
}

function RepsHolder({ radius, stroke, repsCount = 0, isFinished }) {
	const [normalizedRadius, setNormalizedRadius] = useState(0);
	const [circumference, setCircumference] = useState(0);
	const [strokeDashoffset, setStrokeDashoffset] = useState(0);

	useEffect(() => {
		setNormalizedRadius(radius - stroke * 2);
		setCircumference(normalizedRadius * 2 * Math.PI);
		setStrokeDashoffset(circumference - (repsCount / requiredRepsCount) * circumference);
	}, [circumference, normalizedRadius, radius, repsCount, stroke]);

	return (
		<div className="modal__reps">
			<svg
				className="modal__reps-progress"
				width="10vw"
				height="10vw"
				fill="none"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					className="modal__reps-progress--circle"
					cx={radius}
					cy={radius}
					r={normalizedRadius}
					width="10vw"
					style={{ strokeDashoffset }}
					fill="transparent"
					stroke="white"
					strokeDasharray={circumference + " " + circumference}
					strokeWidth={stroke}
				/>
				<circle
					className="modal__reps-progress--circle"
					cx={radius}
					cy={radius}
					r={normalizedRadius}
					width="10vw"
					fill="transparent"
					stroke="white"
					strokeOpacity="0.4"
					strokeWidth={stroke}
				/>
			</svg>

			<div
				className={`modal__reps-count jcc-aic ${
					isFinished ? "modal__reps-count--winner" : ""
				}`}
			>
				{!isFinished ? `${repsCount}/${requiredRepsCount}` : `+${repsCount} Reps`}
			</div>
		</div>
	);
}

function AudioPlayerModalWinner({ modalState }) {
	function CurrentPlayer() {
		if (modalState === listOfModals.dailyFocus) {
			return (
				<div className="modal__audioPlayer-winner--content">
					<img
						className="modal__audioPlayer-winner--gif"
						src="/media/focus/winner1.gif"
						alt=""
					/>

					<img src="/media/focus/winner2.png" alt="" />
				</div>
			);
		}

		if (modalState === listOfModals.gymTactile) {
			return (
				<div className="modal__audioPlayer-winner--content">
					<img
						className="modal__audioPlayer-winner--gif"
						src="/media/gym/winner1.gif"
						alt=""
					/>

					<img src="/media/gym/winner2.png" alt="" />
				</div>
			);
		}

		if (modalState === listOfModals.audiobook) {
			return (
				<div className="modal__audioPlayer-winner--content">
					<img
						className="modal__audioPlayer-winner--gif"
						src="/media/gym/winner1.gif"
						alt=""
					/>

					<img src="/media/gym/winner2.png" alt="" />
				</div>
			);
		}
	}

	return (
		<div className="modal__audioPlayer-winner wh-full jcc-aic">
			<CurrentPlayer />
		</div>
	);
}

export { AudioPlayerModal };
