import { motion } from "framer-motion";
import { useEffect } from "react";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";

import { modulesSections } from "../helpers/listOfSections";

import NotFound from "./404";
import Audiobook from "./audiobook";
import Weekly from "./weekly";
import Welcome from "./welcome";

function ModulesRouter(props) {
	return (
		<Routes>
			<Route
				path={modulesSections.welcome.relativeLink}
				element={<Welcome setModalState={props.setModalState} />}
			/>

			<Route path={modulesSections.weekly.relativeLink} element={<Weekly />} />

			<Route
				path={modulesSections.audiobook.relativeLink}
				element={<Audiobook setModalState={props.setModalState} />}
			/>

			<Route path="/" element={<Modules setModalState={props.setModalState} />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

function Modules() {
	return (
		<div className="modules jcfs-aic w-full">
			<header className="modules__header w-full fixed">
				<img src="/media/modules/modules1.png" alt="" />
			</header>

			<div className="modules__content w-full">
				<div className="modules__content-main jcc-aic wrap">
					<div className="modules__content-module">
						<Link to={modulesSections.welcome.link}>
							<img src="/media/modules/modules2.png" alt="" />
						</Link>
					</div>

					<div className="modules__content-module">
						<Link to={modulesSections.weekly.link}>
							<img src="/media/modules/modules3.png" alt="" />
						</Link>
					</div>

					<div className="modules__content-module">
						<Link to={modulesSections.audiobook.link}>
							<img src="/media/modules/modules4.png" alt="" />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

function ModulesVideoModal() {
	const modulesVideo = {
		visible: { bottom: 0 },
		hidden: { bottom: "-110vh" },
		transition: { bounce: 0 },
	};

	useEffect(() => {
		var video = document.getElementById("videoPlayer");

		if (video.requestFullscreen) {
			video.requestFullscreen();
		} else if (video.mozRequestFullScreen) {
			video.mozRequestFullScreen();
		} else if (video.webkitRequestFullscreen) {
			video.webkitRequestFullscreen();
		} else if (video.msRequestFullscreen) {
			video.msRequestFullscreen();
		}
	}, []);

	return (
		<motion.div
			className="modal__modal modal__modulesVideo jcc-aic"
			variants={modulesVideo}
			transition="transition"
			initial="hidden"
			animate="visible"
			exit="hidden"
		>
			<div className="modal__modulesVideo-holder jcc-aic w-full">
				<video id="videoPlayer" src="/video/videoNew.mp4" autoPlay controls>
					Video Playback
				</video>
			</div>
		</motion.div>
	);
}

export default ModulesRouter;

export { ModulesVideoModal };
