import { Link, Routes, Route } from "react-router-dom";

import { homeSections, settingsSections } from "../helpers/listOfSections";

import NotFound from "./404";
import Notifications from "./notifications";
import Schedule from "./schedule";

function SettingsRouter({ setModalState }) {
	return (
		<Routes>
			<Route
				path={`${settingsSections.schedule.relativeLink}/*`}
				element={<Schedule setModalState={setModalState} />}
			/>

			<Route
				path={`${settingsSections.notifications.relativeLink}/*`}
				element={<Notifications />}
			/>

			<Route path="/" element={<Settings />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

function Settings() {
	return (
		<div className="settings jcfs-aic w-full">
			<header className="settings__header jcc-aic w-full fixed">
				<img src="/media/settings/settings1.1.png" alt="" />

				<Link to={homeSections.profile.link}>
					<img src="/media/back.svg" alt="" className="settings__header-button" />
				</Link>
			</header>

			<div className="settings__content w-full">
				<div className="settings__content-main jcc-aic wrap">
					<div className="settings__content-item">
						<img src="/media/settings/settings1.5.png" alt="" />
					</div>

					<Link to={settingsSections.schedule.link} className="settings__content-item">
						<img src="/media/settings/settings2.png" alt="" />
					</Link>

					<Link
						to={settingsSections.notifications.link}
						className="settings__content-item"
					>
						<img src="/media/settings/settings3.png" alt="" />
					</Link>

					<div className="settings__content-item">
						<img src="/media/settings/settings4.png" alt="" />
					</div>

					<div className="settings__content-item settings__content-item--disabled">
						<img src="/media/settings/settings5.png" alt="" />
					</div>

					<div className="settings__content-item">
						<img src="/media/settings/settings6.png" alt="" />
					</div>

					<div className="settings__content-item settings__content-item--disabled">
						<img src="/media/settings/settings7.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SettingsRouter;
