// enableFooter means "should this section be included at the footer?"

const homeSections = {
	home: {
		id: "home",
		link: "/",
		src: null,
		enableFooter: false,
	},
	focus: {
		id: "focus",
		link: "/focus",
		src: "/media/footer/footerFocus.svg",
		enableFooter: true,
	},
	gym: {
		id: "gym",
		link: "/gym",
		src: "/media/footer/footerGym.svg",
		enableFooter: true,
	},
	community: {
		id: "community",
		link: "/community",
		src: "/media/footer/footerComm.svg",
		enableFooter: true,
	},
	modules: {
		id: "modules",
		link: "/modules",
		src: "/media/footer/footerModules.svg",
		enableFooter: true,
	},
	profile: {
		id: "profile",
		link: "/profile",
		src: "/media/footer/footerProfile.svg",
		enableFooter: true,
	},
};

const settingsSections = {
	settings: {
		id: "settings",
		link: "/profile/settings",
		relativeLink: "/settings",
		src: null,
		enableFooter: false,
	},
	schedule: {
		id: "schedule",
		link: "/profile/settings/schedule",
		relativeLink: "/schedule",
		src: null,
		enableFooter: false,
	},
	notifications: {
		id: "notifications",
		link: "/profile/settings/notifications",
		relativeLink: "/notifications",
		src: null,
		enableFooter: false,
	},
};

const modulesSections = {
	modules: {
		id: "modules",
		link: "/modules",
		relativeLink: "/modules",
		src: null,
		enableFooter: false,
	},
	welcome: {
		id: "welcome",
		link: "/modules/welcome",
		relativeLink: "/welcome",
		src: null,
		enableFooter: false,
	},
	weekly: {
		id: "weekly",
		link: "/modules/weekly",
		relativeLink: "/weekly",
		src: null,
		enableFooter: false,
	},
	audiobook: {
		id: "audiobook",
		link: "/modules/audiobook",
		relativeLink: "/audiobook",
		src: null,
		enableFooter: false,
	},
};

export { homeSections, settingsSections, modulesSections };
