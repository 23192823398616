const links = {
	dailyFocusAudio:
		"https://d1clamuv3orexn.cloudfront.net/focus_dataSet_audio_5aaffa650f7c8c3a47c3b8a5?t=1600756928366",
	gymTactileAudio:
		"https://d1clamuv3orexn.cloudfront.net/gym_item_audio_59ef4ec2c667937c62ec0975?t=1602708963068",
	audiobook:
		"https://d1clamuv3orexn.cloudfront.net/module_data_set_audio_5f446662a09702eb0f527d06?t=1598330898112",
};

export default links;
