import { useEffect, useState } from "react";

const useAudioPlayer = () => {
	const [duration, setDuration] = useState();
	const [curTime, setCurTime] = useState();
	const [playing, setPlaying] = useState(false);
	const [clickedTime, setClickedTime] = useState();
	const [audio, setAudio] = useState(null);

	useEffect(() => {
		setAudio(document.getElementById("audio"));
	}, []);

	useEffect(() => {
		const setAudioData = () => {
			setDuration(audio.duration);
			setCurTime(audio.currentTime);
		};

		const setAudioTime = () => setCurTime(audio.currentTime);

		if (duration && curTime === duration) {
			setPlaying(false);
		}

		audio?.addEventListener("loadeddata", setAudioData);
		audio?.addEventListener("timeupdate", setAudioTime);

		if (clickedTime && clickedTime !== curTime) {
			audio.currentTime = clickedTime;

			setClickedTime(null);
		}

		return () => {
			audio?.removeEventListener("loadeddata", setAudioData);
			audio?.removeEventListener("timeupdate", setAudioTime);
		};
	}, [audio, clickedTime, curTime, duration]);

	useEffect(() => {
		playing ? audio?.play() : audio?.pause();
	}, [playing, audio]);

	return {
		curTime,
		duration,
		playing,
		setPlaying,
		setCurTime,
		setClickedTime,
	};
};

export { useAudioPlayer };
