import { Link } from "react-router-dom";

import { modulesSections } from "../helpers/listOfSections";

function Weekly() {
	return (
		<div className="weekly jcfs-aic w-full">
			<header className="weekly__header jcc-aic w-full fixed">
				<img src="/media/weekly/notif1.png" alt="" />

				<Link to={modulesSections.modules.link}>
					<img src="/media/back.svg" alt="" className="weekly__header-button" />
				</Link>
			</header>

			<div className="weekly__content w-full">
				<div className="weekly__content-main jcc-aic wrap">
					<div className="weekly__content-item">
						<img src="/media/weekly/weekly1.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Weekly;
