import { motion } from "framer-motion";
import { Link, Routes, Route } from "react-router-dom";

import listOfModals from "../helpers/listOfModals";
import { settingsSections } from "../helpers/listOfSections";

import NotFound from "./404";
import SettingsRouter from "./settings";

function ProfileRouter(props) {
	return (
		<Routes>
			<Route
				path={`${settingsSections.settings.relativeLink}/*`}
				element={<SettingsRouter setModalState={props.setModalState} />}
			/>

			<Route path="/" element={<Profile setModalState={props.setModalState} />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

function Profile({ setModalState }) {
	return (
		<div className="profile jcfs-aic w-full">
			<header className="profile__header w-full fixed">
				<div className="profile__header-content jcc-aic">
					<img src="/media/profile/profile1.png" alt="" />

					<img
						src="/media/hamburger.svg"
						alt=""
						className="profile__header-hamburger"
						onClick={() => setModalState(listOfModals.profileMenu)}
					/>
				</div>
			</header>

			<div className="profile__content w-full">
				<img src="/media/profile/profile2.png" alt="" />
			</div>
		</div>
	);
}

function ProfileMenuModal({ setModalState }) {
	const profileMenu = {
		visible: { bottom: 0 },
		hidden: { bottom: "-110vw" },
		transition: { bounce: 0 },
	};

	return (
		<motion.div
			className="modal__modal modal__profileMenu jcc-aic"
			variants={profileMenu}
			transition="transition"
			initial="hidden"
			animate="visible"
			exit="hidden"
		>
			<div className="modal__profileMenu-holder">
				<div className="modal__profileMenu-item modal__profileMenu-item--disable">
					<img src="/media/profile/profilemenu1.png" alt="" />
				</div>

				<Link
					className="modal__profileMenu-item"
					to={settingsSections.settings.link}
					onClick={() => setModalState(null)}
				>
					<img src="/media/profile/profilemenu2.png" alt="" />
				</Link>
			</div>
		</motion.div>
	);
}

export default ProfileRouter;

export { ProfileMenuModal };
