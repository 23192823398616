import Play from "./play";
import Pause from "./pause";
import Bar from "./bar";

function Audio({ curTime, duration, playing, setPlaying, setClickedTime, audioSource }) {
	return (
		<div className="player jcc-aic w-full">
			<audio id="audio">
				<source src={audioSource} />
				Your browser does not support the <code>audio</code> element.
			</audio>

			<div className="controls jcc-aic w-full">
				{playing ? (
					<Pause handleClick={() => setPlaying(false)} />
				) : (
					<Play handleClick={() => setPlaying(true)} />
				)}

				<Bar
					curTime={curTime}
					duration={duration}
					setPlaying={setPlaying}
					onTimeUpdate={(time) => setClickedTime(time)}
				/>
			</div>
		</div>
	);
}

export default Audio;
