const listOfModals = {
	demo: 1,
	tactile: 2,
	profileMenu: 3,
	schedule: 4,
	dailyFocus: 5,
	modulesVideo: 6,
	gymTactile: 7,
	audiobook: 8,
};

export default listOfModals;
