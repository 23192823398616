import { Link } from "react-router-dom";

import listOfModals from "../helpers/listOfModals";
import { modulesSections } from "../helpers/listOfSections";

function Audiobook({ setModalState }) {
	return (
		<div className="audiobook jcfs-aic w-full">
			<header className="audiobook__header jcc-aic w-full fixed">
				<Link to={modulesSections.modules.link}>
					<img src="/media/back.svg" alt="" className="audiobook__header-button" />
				</Link>
			</header>

			<div className="audiobook__content w-full">
				<div className="audiobook__content-main jcc-aic wrap">
					<div className="audiobook__content-item">
						<img src="/media/audiobook/audio1.png" alt="" />
					</div>

					<div
						className="audiobook__content-item"
						onClick={() => setModalState(listOfModals.audiobook)}
					>
						<img src="/media/audiobook/audio2.png" alt="" />
					</div>

					<div className="audiobook__content-item">
						<img src="/media/audiobook/audio3.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Audiobook;
