import { Link } from "react-router-dom";

import { homeSections } from "../helpers/listOfSections";

function Footer({ currentSection, setCurrentSection }) {
	return (
		<footer className="footer jcc-aic w-full">
			<nav className="footer__navs h-full jcsb-aic">
				{Object.values(homeSections).map((value) => {
					let isCurrent = value.id === currentSection;

					if (!value.enableFooter) return null;

					return (
						<FooterNavElement
							src={value.src}
							id={value.id}
							link={value.link}
							isCurrent={isCurrent}
							setCurrentSection={setCurrentSection}
							key={value.id}
						/>
					);
				})}
			</nav>
		</footer>
	);
}

function FooterNavElement({ src, id, link, isCurrent = false, setCurrentSection }) {
	return (
		<Link
			className={`footer__navs-element h-full jcc-aic ${
				isCurrent ? "footer__navs-current" : ""
			}`}
			onClick={() => setCurrentSection(id)}
			to={link}
		>
			<img src={src} alt={id} />
		</Link>
	);
}

export default Footer;
