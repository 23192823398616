import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export default function Bar(props) {
	const { duration, curTime, onTimeUpdate } = props;

	const curPercentage = (curTime / duration) * 100;

	function formatDuration(duration) {
		momentDurationFormatSetup(moment);

		return moment.duration(duration, "seconds").format("mm:ss", { trim: false });
	}

	function calcClickedTime(event, isMobile) {
		let clickPositionInPage = event.pageX;

		if (isMobile) {
			clickPositionInPage = event.targetTouches[0]
				? event.targetTouches[0].pageX
				: event.changedTouches[event.changedTouches.length - 1].pageX;
		}

		const bar = document.querySelector(".bar__progress");
		const barStart = bar?.getBoundingClientRect().left + window.scrollX;
		const barWidth = bar?.offsetWidth;
		const clickPositionInBar = clickPositionInPage - barStart;
		const timePerPixel = duration / barWidth;

		return timePerPixel * clickPositionInBar;
	}

	function handleTimeDrag(event, isMobile = false) {
		onTimeUpdate(calcClickedTime(event, isMobile));

		const updateTimeOnMove = (eventMove) => {
			if (isMobile && eventMove.cancelable) eventMove.preventDefault();

			if (curTime < duration) {
				onTimeUpdate(calcClickedTime(eventMove, isMobile));
			}
		};

		if (isMobile) {
			document.addEventListener("touchmove", updateTimeOnMove, { passive: false });
			document.addEventListener("touchend", () => {
				document.removeEventListener("touchmove", updateTimeOnMove);
			});
		} else {
			document.addEventListener("mousemove", updateTimeOnMove);
			document.addEventListener("mouseup", () => {
				document.removeEventListener("mousemove", updateTimeOnMove);
			});
		}
	}

	return (
		<div className="bar">
			<div className="bar__time">{formatDuration(curTime)}</div>

			<div
				className="bar__progress"
				style={{
					background: `linear-gradient(to right, black ${curPercentage}%, rgba(0, 0, 0, 0.1) 0)`,
				}}
				onMouseDown={(event) => handleTimeDrag(event)}
				onTouchStart={(event) => handleTimeDrag(event, true)}
			>
				<span className="bar__progress-knob" style={{ left: `${curPercentage - 2}%` }} />
			</div>

			<div className="bar__time">{formatDuration(duration)}</div>
		</div>
	);
}
